<!-- API接口调用记录查询 -->
<template>
  <div>
    <div class="searchAreaCss">
      <SearchArea @initInterfaceHistoryList="initInterfaceHistoryList" />
    </div>
    <div style="margin-top: 6px">
      <a-table
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="interfaceHistoryList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import { getInterfaceHistoryListPage } from "@/api/application/interface";
import moment from "moment";
import SearchArea from "./components/mySearchArea.vue";
export default defineComponent({
  name: "Interface",
  components: {
    SearchArea,
  },
  setup() {
    const state = reactive({
      searchParam: {
        pageIndex: 1,
        pageSize: 10,
      },
      interfaceHistoryList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "账户名称",
        dataIndex: "accountInfo.uname",
        key: "accountName",
        width: "20%",
      },
      {
        title: "接口名称",
        dataIndex: "interfaceInfo.apiName",
        key: "apiName",
        width: "20%",
      },
      {
        title: "接口编码",
        dataIndex: "interfaceInfo.apiCode",
        key: "apiCode",
        width: "20%",
      },
      {
        title: "执行时间",
        dataIndex: "createDate",
        key: "createDate",
        align: "center",
        width: "20%",
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "remark",
        width: "20%",
      },
    ];
    onMounted(() => {
      initInterfaceHistoryList(state.searchParam);
    });
    const initInterfaceHistoryList = (searchParam) => {
      state.loading = true;
      getInterfaceHistoryListPage(searchParam).then((resp) => {
        if (resp) {
          state.interfaceHistoryList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initInterfaceHistoryList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initInterfaceHistoryList(state.searchParam);
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initInterfaceHistoryList,
      onChangePage,
      onShowSizeChange,
    };
  },
});
</script>
<style lang='scss' scoped>
.searchAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>